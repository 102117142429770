(function ($, generic, site) {
  var titleTemplate;
  var titleVars = {};
  var $titles = $();
  var titleClass = 'signin-block__authed__title';
  var rClass = titleClass + '--rendered';

  function _renderTitles() {
    if (titleTemplate !== undefined) {
      $titles.html(Mustache.render(titleTemplate, titleVars)).addClass(rClass);
    }
  }

  // Check if user is loyalty member
  function isLoyaltyMember() {
    var is_loyalty_member = site.userInfoCookie.getValue('is_loyalty_member');

    return is_loyalty_member === '1';
  }

  // Check if user is pro member
  function isProMember() {
    var is_pro_member = site.userInfoCookie.getValue('is_pro');

    return is_pro_member === '1';
  }

  // Replace first term in Nav title (set in Drupal), with users first name
  function setUserName() {
    var $elem = $('.signin-block__authed__title-first');
    var suffix = $elem.data('username-suffix');

    if (titleVars.first_name !== null) {
      $elem.html(titleVars.first_name + suffix);
    }
  }

  function getLoyaltyLevel() {
    var loyaltyLevel = site.userInfoCookie.getValue('loyalty_level');

    return loyaltyLevel;
  }

  function getLoyaltyPoints() {
    var points = site.userInfoCookie.getValue('points');

    return points;
  }

  Drupal.behaviors.signinBlock = {
    attach: function (context) {
      var $blocks = $('.js-signin-block', context);

      $titles = $titles.add($('.' + titleClass, $blocks));
      var pathname = window.location.pathname;
      var firstName = site.userInfoCookie.getValue('first_name');

      if (firstName) {
        titleVars.first_name = firstName;
      }
      if (!titleTemplate) {
        titleTemplate = $titles.first().html();
      }
      _renderTitles();
      setUserName();
      isLoyaltyMember();

      var $forgotPassword = $('.js-forgot-password', context);

      if ($forgotPassword.length > 0) {
        site.signin.forgotPassword({
          resetPassword: true,
          emailNode: $('.js-return-user-email', context),
          errorListNode: ' ',
          forgotPasswordLink: $forgotPassword
        });
      }

      if (Drupal.settings && Drupal.settings.common && Drupal.settings.common.adv_legal) {
        var $commercialCheckbox = $('.js-commercial-reg');
        var $commercialLabel = $('.js-commercial-permission .picker-checkbox');
        var $mobileContainer = $('.js-mobile-container');

        $commercialLabel.on('click', function () {
          if ($commercialCheckbox.is(':checked')) {
            $mobileContainer.removeClass('hidden');
          } else {
            $mobileContainer.addClass('hidden');
          }
        });
      }

      $blocks.each(function () {
        var $block = $(this);
        var $registerForm = $('.signin-block__form--register', this);
        var $forms = $('.signin-block__form', $block);
        var $returnUrls = $('input[name="RETURN_URL"]', $forms);
        var $toggleRegisterPass = $('.signin-block__toggle-password__option', $registerForm);
        var $registerPass = $('.signin-block__field--password', $registerForm);
        var $showSigninTrigger = $('.js-show-signin-block', this);
        var $showRegisterTrigger = $('.js-show-register-block', this);
        var $loyaltyRegisterToggle = $('.js-loyalty-join-toggle', this);
        var $loyaltyEnrollBlock = $('.js-loyalty-join-content', this);
        var $signinTabBlock = $('.js-signin-tab', this);
        var showPass = false;

        if (
          Drupal.settings &&
          Drupal.settings.reset_return_url &&
          pathname.search('password_request|confirm|signin') < 0
        ) {
          $returnUrls.val(pathname);
        }

        // Hide the image/link for joining loyalty, if the user is a pro member.
        if (isProMember()) {
          $('.js-signin-block-authed-loyalty').addClass('hidden');
        }

        if (isLoyaltyMember()) {
          var loyaltyLevelName = site.userInfoCookie.getValue('loyalty_level_name');
          var loyaltyPointsValue = site.userInfoCookie.getValue('available') || getLoyaltyPoints();
          var loyaltyLevel = Number(getLoyaltyLevel());
          var $loyaltyMemberTier = $('.js-loyalty-member-tier', this);
          var $loyaltyGnavPoint = $('.js-gnav-account-loyalty-point');

          if (loyaltyLevelName !== null) {
            $('.js-loyalty-member-level', this).text(loyaltyLevelName);
          } else if ($loyaltyMemberTier.length) {
            // get value and display name per loyalty_level
            if (loyaltyLevel >= 0 && loyaltyLevel <= 5) {
              $('.js-loyalty-member-tier-' + loyaltyLevel).removeClass('hidden');
              $('.js-loyalty-member-rewards-section').addClass(
                'active-loyalty-member-tier-' + loyaltyLevel
              );
            }
          } else {
            $('.js-loyalty-member-level', this).text(loyaltyLevel);
          }
          $('.js-loyalty-member-points', this).text(loyaltyPointsValue);
          if ($loyaltyGnavPoint.length) {
            $loyaltyGnavPoint.removeClass('hidden');
            $('.js-gnav-loyalty-member-points').text(loyaltyPointsValue);
          }
        }

        function togglePassword() {
          showPass = !showPass;
          $registerPass.get(0).type = showPass ? 'text' : 'password';
          $block.toggleClass('signin-block--show-password', showPass);
        }

        $toggleRegisterPass.on('click', function (event) {
          event.preventDefault();
          togglePassword();
        });

        $registerForm.on('submit', function () {
          if (showPass) {
            togglePassword();
          }
        });

        // User is not logged in, and clicks to see the signin form.
        $showSigninTrigger.on('click', function (event) {
          event.preventDefault();
          $signinTabBlock.removeClass('active');
          $(this).addClass('active');
          $block.removeClass('signin-block--register');
          $block.addClass('signin-block--signin');
        });

        // User is not logged in, and clicks to see the registration form.
        $showRegisterTrigger.on('click', function (event) {
          event.preventDefault();
          $signinTabBlock.removeClass('active');
          $(this).addClass('active');
          $block.removeClass('signin-block--signin');
          $block.addClass('signin-block--register');
        });

        // User is logged in, and clicks to see OR hide the loyalty program form.
        $loyaltyRegisterToggle.on('click', function (event) {
          event.preventDefault();
          $('.js-signin-block-authed').toggleClass('hidden');
          $('.js-loyalty-join-content').toggleClass('hidden');
          // To clear the error messages
          $loyaltyEnrollBlock.find('.js-loyalty-err-msg').empty();
          $('.gnav-util__content-close')
            .attr('id', 'js-cbox-close')
            .removeClass('js-gnav-util-close');
          // To hide the loyalty register content on overlay
          $loyaltyEnrollBlock
            .find('.loyalty-join__subheading, .js-loyalty-box-close,.js-loyalty-join-terms')
            .addClass('hidden');
          // To show the loyalty signin content on overlay
          $loyaltyEnrollBlock
            .find(
              '.loyalty-join__subheading_signin,.js-loyalty-box-close-signin,.loyalty-join__terms__signin'
            )
            .removeClass('hidden');

          // Toggling these classes changes the width from very thin to 100% and back again,
          // and ensures the correct text is shown for the nav link.
          // Toggling these classes changes the width from very thin to 100% and back again,
          // and ensures the correct text is shown for the nav link.
          $('.js-gnav-util')
            .toggleClass('gnav-util--account')
            .toggleClass('gnav-util--loyalty-join');
          $('.js-gnav-util__content')
            .toggleClass('gnav-util__content--account')
            .toggleClass('gnav-util__content--loyalty-join');
        });
      });

      if (generic.cookie('USER_RECOGNIZED')) {
        $blocks.addClass('signin-block--signin');
      }

      // If URL has #register, display $registerForm by default
      if (window.location.hash === '#register') {
        $('.js-show-signin-block', context).removeClass('active');
        $('.js-show-register-block', context).removeClass('active').addClass('active');
        $('.js-new-account', context).show();
        $('.js-return-user', context).hide();
      }
    }
  };

  $(document).on('user.loaded', function (event, val) {
    // This is brittle but there's no other way to tell from the data we get.
    if (!$titles.length) {
      $titles = $('.' + titleClass);
    }
    if (!titleTemplate) {
      titleTemplate = $titles.first().html();
    }
    titleVars.first_name = val.first_name;

    var is_loyalty_member = isLoyaltyMember();
    var loyaltyMemberInfo = $('.signin-block__authed__loyalty__member');
    var registerLoyaltyLink = $('.signin-block__authed__loyalty__non-member__register');

    if (!is_loyalty_member) {
      loyaltyMemberInfo.hide();
      registerLoyaltyLink.show();
    }
    if (is_loyalty_member) {
      registerLoyaltyLink.hide();
      loyaltyMemberInfo.show();
    }

    // Hide the image/link for joining loyalty, if the user is a pro member.
    if (isProMember()) {
      $('.js-signin-block-authed-loyalty').addClass('hidden');
    }

    // If user is ever signed in, permanently store a "USER_REC" cookie. This
    // only expires once a year.
    if (val.signed_in) {
      $('body').addClass('signed-in');
      generic.cookie('USER_RECOGNIZED', 1, {
        path: '/',
        expires: 365
      });
    }

    _renderTitles();
    setUserName();
    isLoyaltyMember();
  });
})(jQuery, window.generic || {}, window.site || {});
